import React, {useEffect, useState} from 'react';
import './index.scss';
import DashboardCard from "../../components/dashboardCard";
import {Grid, Skeleton} from "@mui/material";
import PLatformClient from "../../components/client/Client";
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import {
    cheerfulFiestaPalette,
} from '@mui/x-charts/colorPalettes';

const size = {
    width: 600,
    height: 300,
};

function DashboardPage() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([])
    const [moreData, setMoreData] = useState({})
    const [orders, setOrders] = useState([])
    const [ordersByState, setOrdersByState] = useState(null)
    const [ordersByStateMoney, setOrdersByStateMoney] = useState(null)

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    useEffect(() => {
        const fetchData = async () => {
            const response = await PLatformClient.get('/dashboard');
            setData(response.ORDERS)
            setMoreData(response)

            setLoading(false)
        };


        fetchData();
    }, []);

    function formatNumber(num) {
        if (Math.abs(num) > 999999) {
            return (Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1)) + 'M';
        } else if (Math.abs(num) > 999) {
            return (Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)) + 'k';
        } else {
            return Math.sign(num) * Math.abs(num);
        }
    }

    if (loading) {
        return (
            <div className="App-page-content dashboard-main">
                <Grid container spacing={6} columns={12}>
                    <Grid item xs={6}>
                        <Skeleton animation="wave" variant="rounded" height={200} />
                    </Grid>
                    <Grid item xs={3}>
                        <Skeleton animation="wave" variant="rounded" height={200} />
                    </Grid>
                    <Grid item xs={3}>
                        <Skeleton animation="wave" variant="rounded" height={200} />
                    </Grid>
                    <Grid item xs={3}>
                        <Skeleton animation="wave" variant="rounded" height={200} />
                    </Grid>
                    <Grid item xs={3}>
                        <Skeleton animation="wave" variant="rounded" height={200} />
                    </Grid>
                    <Grid item xs={6}>
                        <Skeleton animation="wave" variant="rounded" height={200} />
                    </Grid>
                </Grid>
            </div>
        )
    }

    const calculateRevenue = (timeframe) => {
        let profit = 0;
        data.forEach(order => {
            if (timeframe === "month") {
                if (order.date && new Date(order.date).getMonth() === new Date().getMonth()) {
                    if (!Number.isNaN(order.sellPrice) && !Number.isNaN(order.sellPrice)) {
                        profit += order.sellPrice
                    }
                }
            } else {
                if (order.date && new Date(order.date).getFullYear() === new Date().getFullYear()) {
                    if (!Number.isNaN(order.sellPrice) && !Number.isNaN(order.sellPrice)) {
                        profit += order.sellPrice
                    }
                }
            }


        })

        return profit;
    }

    const calculateProfit = (timeframe) => {
        let profit = 0;
        data.forEach(order => {
            if (timeframe === "month") {
                if (order.date && new Date(order.date).getMonth() === new Date().getMonth()) {
                    if (!Number.isNaN(order.sellPrice) && !Number.isNaN(order.buyPrice)) {
                        if (order.shopifyStore === "wall-imp") {
                            profit += (order.sellPrice * 0.85) - order.buyPrice
                        } else {
                            profit += - 0.3 + ((order.sellPrice - (order.sellPrice * 0.029)) - order.buyPrice)
                        }
                    }
                }
            } else {
                if (order.date && new Date(order.date).getFullYear() === new Date().getFullYear()) {
                    if (!Number.isNaN(order.sellPrice) && !Number.isNaN(order.buyPrice)) {
                        if (order.shopifyStore === "wall-imp") {
                            profit += (order.sellPrice * 0.85) - order.buyPrice
                        } else {
                            profit += - 0.3 + ((order.sellPrice - (order.sellPrice * 0.029)) - order.buyPrice)
                        }
                    }
                }
            }


        })

        return profit;
    }

    const calculateAov = () => {
        let sum = 0;
        data.forEach(order => {
            sum += order.sellPrice;
        })

        return sum / data.length;
    }


    return (
         <div className="App-page-content dashboard-main">
             <Grid container spacing={6} columns={25}>
                 <Grid item xs={6}>
                     <DashboardCard data={{ v: '2', title: "Orders", "insight": data?.length }}/>
                 </Grid>
                 <Grid item xs={6}>
                     <DashboardCard data={{ v: '1', title: "Net Income (MTD)", "insight": "$" + formatNumber(calculateProfit("month").toFixed(2)) }}/>
                 </Grid>
                 <Grid item xs={6}>
                     <DashboardCard data={{ v: '4', title: "Net Income (YTD)", "insight": "$" + formatNumber(calculateProfit("year").toFixed(2)) }}/>
                 </Grid>
                 <Grid item xs={6}>
                     <DashboardCard data={{ v: '3', title: "AOV", "insight": "$" + calculateAov(data).toFixed(2) }}/>
                 </Grid>
             </Grid>
             <Grid style={{ marginTop: 10 }} container spacing={6} columns={25}>

                 <Grid item xs={6}>
                     <DashboardCard data={{ v: '2', title: "Total Products", "insight": formatNumber(moreData.TOTAL_PRODUCTS) }}/>
                 </Grid>
                 <Grid item xs={6}>
                     <DashboardCard data={{ v: '1', title: "Total Revenue (MTD)", "insight": "$" + formatNumber(calculateRevenue("month").toFixed(2)) }}/>
                 </Grid>
                 <Grid item xs={6}>
                     <DashboardCard data={{ v: '3', title: "Total Revenue (YTD)", "insight": "$" + formatNumber(calculateRevenue("year").toFixed(2))  }}/>
                 </Grid>
                 <Grid item xs={6}>
                     <DashboardCard data={{ v: '3', title: "Total Variants", "insight": formatNumber(moreData.TOTAL_VARIANTS) }}/>
                 </Grid>
                 <Grid item xs={6}>
                     <DashboardCard data={{ v: '4', title: "Active Stores", "insight": moreData.TOTAL_STORES }}/>
                 </Grid>
                 <Grid item xs={6}>
                     <DashboardCard data={{ v: '4', title: "Next Payout", "insight": "$" + moreData.PAYOUT }}/>
                 </Grid>
             </Grid>
             <Grid style={{ marginTop: 50 }} container spacing={6} columns={16}>
                 <Grid item xs={6}>
                     <iframe style={{ background: '#FFFFFF', border: 'none', boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)'  }}  width="500" height="380" src="https://charts.mongodb.com/charts-project-0-ykror/embed/charts?id=65eefef0-38af-4ce8-8987-fbb9974bbc0e&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                 </Grid>
                 <Grid item xs={6}>
                     <iframe style={{ background: '#FFFFFF', border: 'none', boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)'  }}  width="500" height="380" src="https://charts.mongodb.com/charts-project-0-ykror/embed/charts?id=65eddb95-e819-48ad-8495-6a08a1dd4f93&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                 </Grid>
                 <Grid item xs={3}>
                     <iframe style={{ background: '#FFFFFF', border: 'none', boxShadow: '0 2px 10px 0 rgba(70, 76, 79, .2)'  }}  width="320" height="380" src="https://charts.mongodb.com/charts-project-0-ykror/embed/charts?id=65eddb08-08a4-4373-807e-1a4bbce4286f&maxDataAge=3600&theme=light&autoRefresh=true"></iframe>
                 </Grid>
             </Grid>

        </div>
    );
}

export default DashboardPage;
